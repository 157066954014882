import React, {useState, useCallback} from 'react';
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import InfoSection from "../components/InfoSection";
import {
  homeObjOne,
  homeObjTwo,
  homeObjThree,
  homeObjFour,
  homeObjFive,
  homeObjSix, homeObjSeven
} from "../components/InfoSection/Data";
import Services from '../components/Services';
import Feedbacks from '../components/Feedbacks';
import Footer from "../components/Footer";
import HowItWorksSection from "../components/HowItWorksSection";


const Home = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle}/>
      <Navbar toggle={toggle}/>
      <HeroSection />
      <InfoSection {...homeObjOne} style={{paddingTop: '100px',}}/>
      <InfoSection {...homeObjTwo}/>
      <InfoSection {...homeObjThree}/>
      <InfoSection {...homeObjFour} />
      <InfoSection {...homeObjFive} />
      <InfoSection {...homeObjSix} />
      <HowItWorksSection {...homeObjSeven} />
      <Feedbacks />
      <Services />
      <Footer />
    </>
  );
};

export default Home;
