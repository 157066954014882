import React, { useState, useEffect, useCallback } from "react";
import { FaBars } from 'react-icons/fa'
import { IconContext } from 'react-icons/lib'
import {animateScroll as scroll} from 'react-scroll'

// import Logo from '../../images/logo2.png'
import Logo from '../../images/logo.jpeg'
import {
  Nav,
  NavLogo,
  NavbarContainer,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
  NavLogoCircle
} from './NavbarElements'

const Navbar = ({toggle}) => {
  const [scrollNav, setScrollNav] = useState(false)

  // const changeNav1 = () => {
  //   if (window.scrollY >= 80) {
  //     setScrollNav(true)
  //   } else {
  //     setScrollNav(false)
  //   }
  // }

  const changeNav = useCallback(() => {
    if (window.scrollY >= 80) {
      setScrollNav(true)
    } else {
      setScrollNav(false)
    }
  }, [scrollNav])

  useEffect(() => {
    window.addEventListener('scroll', changeNav)
  }, []);

  const toggleHome = useCallback(() => {
    scroll.scrollToTop()
  }, [])

  return (
    <>
      <IconContext.Provider value={{color: scrollNav ? '#fc7404' : '#fff'}}>
       <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            {/*<NavLogo to="/">*/}
            {/*  ABTSolar*/}
            {/*</NavLogo>  */}
            {/*<NavLogo onClick={toggleHome} src={Logo}/>*/}
            <NavLogoCircle onClick={toggleHome} src={Logo}/>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              <NavItem>
                <NavLinks to="about" smooth={true} duration={500} spy={true} exact='true' offset={-80} scrollNav={scrollNav}>
                  Quem somos
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="mission" smooth={true} duration={500} spy={true} exact='true' offset={-80} scrollNav={scrollNav}>
                  Nossa missão
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="vision" smooth={true} duration={500} spy={true} exact='true' offset={-80} scrollNav={scrollNav}>
                  Visão
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="values" smooth={true} duration={500} spy={true} exact='true' offset={-80} scrollNav={scrollNav}>
                  Valores
                </NavLinks>
              </NavItem>
            </NavMenu>
            <NavBtn hidden>
              {/*<NavBtnLink to="/">Entre em contato</NavBtnLink>*/}
            </NavBtn>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  )
}

export default Navbar;
