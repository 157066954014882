import React, {useCallback, useState} from 'react';

import Video from '../../videos/video.mp4'
import Gif from '../../videos/gif.gif'
import Image from '../../images/19.jpeg'
import {
    HeroContainer,
    VideoBg,
    HeroBg,
    WhatsAppIcon,
    HeroContent,
    HeroH1,
    HeroP,
    HeroBtnWrapper,
    ImageBg,
    EmailLink, GifBg
} from './HeroElements'
import { Button }  from '../ButtonElements'

const HeroSection = () => {
  const [hover, setHover] = useState(false)

  const onHover = useCallback(() => {
    setHover(!hover)
  }, [hover])

  const onSubmit = useCallback(
    () => {
      const message = 'Olá, estou entrando em contato pois gostaria de realizar um orçamento de instalação do sistema de placas fotovoltaicas'
      const number = '5519981699615'
      let url = `https://api.whatsapp.com/send?phone=${number}`
      url += `&text=${encodeURI(message)}&app_absent=0`

      window.open(url);
    },
    [],
  );


  return (
    <HeroContainer>
      <HeroBg>
        <VideoBg autoPlay loop muted src={Video} type='video/mp4' />
        {/*<GifBg autoPlay loop muted src={Gif} />*/}
        {/*<ImageBg src={Image} />*/}
      </HeroBg>
      <HeroContent>
        <HeroH1>Já imaginou sua conta de energia até 95% mais barata?</HeroH1>
        {/*<HeroP>*/}
        {/*  Nós da ABT Solar podemos te ajudar a sair apenas da imaginação.*/}
        {/*</HeroP>*/}
        <HeroBtnWrapper>
          <Button to="contact" onMouseEnter={onHover} onMouseLeave={onHover} primary dark onClick={onSubmit}>
            Peça um orçamento via WhatsApp {hover ? <WhatsAppIcon /> : ''}
          </Button>
          <EmailLink href="mailto:abtsolarsolucoesenergeticas@gmail.com?subject=Solicitação de orçamento" target="_blank">
            Ou via e-mail, clicando aqui
          </EmailLink>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
