import React from 'react'
import Admin from '../../components/Admin'
import ScrollToTop from "../../components/ScrollToTop";

const AdminPage = () => {
  return (
    <>
      <ScrollToTop/>
      <Admin />
    </>
  )
}

export default AdminPage;