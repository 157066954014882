import React, {useCallback} from 'react';
import {animateScroll as scroll} from 'react-scroll'

import {
  FooterContainer, FooterLink,
  FooterLinkItems,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkTitle,
  FooterWrap, SocialIconLink, SocialIcons, SocialLogo, SocialMedia, SocialMediaWrap, WebsiteRights
} from "./FooterElements";
import {FaFacebook, FaInstagram} from 'react-icons/fa';
import {NavLogo} from "../Navbar/NavbarElements";
import Logo from "../../images/logo2.png";

const Footer = () => {
  const toggleHome = useCallback(() => {
    scroll.scrollToTop()
  }, [])

  return (
    <FooterContainer>
      <FooterWrap>
        {/*<FooterLinksContainer>*/}
        {/*  <FooterLinksWrapper>*/}
        {/*    <FooterLinkItems>*/}
        {/*      <FooterLinkTitle>Redes Sociais</FooterLinkTitle>*/}
        {/*      <FooterLink to="/">Instagram</FooterLink>*/}
        {/*      <FooterLink to="/">Facebook</FooterLink>*/}
        {/*    </FooterLinkItems>*/}
        {/*    <FooterLinkItems>*/}
        {/*      <FooterLinkTitle>Sobre nós2</FooterLinkTitle>*/}
        {/*      <FooterLink to="/about">Como funciona</FooterLink>*/}
        {/*      <FooterLink to="/about">Teste 2</FooterLink>*/}
        {/*      <FooterLink to="/about">Teste 3</FooterLink>*/}
        {/*      <FooterLink to="/about">Termos de serviço</FooterLink>*/}
        {/*    </FooterLinkItems>*/}
        {/*  </FooterLinksWrapper>*/}
        {/*  <FooterLinksWrapper>*/}
        {/*    <FooterLinkItems>*/}
        {/*      <FooterLinkTitle>Sobre nós3</FooterLinkTitle>*/}
        {/*      <FooterLink to="/about">Como funciona</FooterLink>*/}
        {/*      <FooterLink to="/about">Teste 2</FooterLink>*/}
        {/*      <FooterLink to="/about">Teste 3</FooterLink>*/}
        {/*      <FooterLink to="/about">Termos de serviço</FooterLink>*/}
        {/*    </FooterLinkItems>*/}
        {/*    <FooterLinkItems>*/}
        {/*      <FooterLinkTitle>Sobre nós4</FooterLinkTitle>*/}
        {/*      <FooterLink to="/about">Como funciona</FooterLink>*/}
        {/*      <FooterLink to="/about">Teste 2</FooterLink>*/}
        {/*      <FooterLink to="/about">Teste 3</FooterLink>*/}
        {/*      <FooterLink to="/about">Termos de serviço</FooterLink>*/}
        {/*    </FooterLinkItems>*/}
        {/*  </FooterLinksWrapper>*/}
        {/*</FooterLinksContainer>*/}
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to='/'>
              <NavLogo src={Logo} onClick={toggleHome}/>
            </SocialLogo>
            <WebsiteRights> ABT Solar © - Piracicaba/SP - {new Date().getFullYear()} Todos os direitos reservados.  <br/>
              Celular: <b>(19) 98169-9615</b><br/>
              E-mail: <b>abtsolarsolucoesenergeticas@gmail.com</b><br/>
              CNPJ: <b>40.968.940/0001-93</b>
            </WebsiteRights>
            <SocialIcons>
              <SocialIconLink href="https://www.facebook.com/ABT-Solar-110269511132044/" target="_blank" aria-label="Facebook">
                <FaFacebook />
              </SocialIconLink>
              <SocialIconLink href="https://www.instagram.com/invites/contact/?i=q4sj0im5upd3&utm_content=lf3xe7b" target="_blank" aria-label="Instagram">
                <FaInstagram />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
