import React from 'react';
import {Button} from '../ButtonElements';
import {Column2, ImgWrap, Column1, BtnWrap, Img, Heading, InfoRow, InfoContainer, InfoWrapper, TextWrapper, TopLine, Subtitle} from './InfoElements'

const InfoSection = ({lightBg, id, imgStart, topLine, lightText, headLine, darkText, description, img, alt, buttonLabel, primary, dark, dark2, showButton, first}) => {
  return (
    <>
     <InfoContainer lightBg={lightBg} id={id} first={first}>
       <InfoWrapper>
         <InfoRow imgStart={imgStart}>
           <Column1>
             <TextWrapper>
               <TopLine>{topLine}</TopLine>
               <Heading lightText={lightText}>{headLine}</Heading>
                <Subtitle darkText={darkText}>{description}</Subtitle>
                {showButton && <BtnWrap>
                 <Button
                   to="home"
                   smooth={true}
                   duration={500}
                   spy={true}
                   exact="true"
                   offset={-80}
                   primary={primary ? 1: 0}
                   dark={dark ? 1 : 0}
                   dark2={dark2 ? 1: 0}
                 >
                   {buttonLabel}
                 </Button>
               </BtnWrap>}
               
             </TextWrapper>
           </Column1>
           <Column2>
             <ImgWrap>
               <Img src={img} alt={alt}/>
             </ImgWrap>
           </Column2>
         </InfoRow>
       </InfoWrapper>
     </InfoContainer>
    </>
  );
};

export default InfoSection;
