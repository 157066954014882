import React from 'react'
import Icon1 from '../../images/svg-1.svg'
import Icon2 from '../../images/svg-1.svg'
import Icon3 from '../../images/svg-1.svg'
import {ServicesContainer, ServicesWrapper, ServicesH1, ServicesH2, ServicesCard, ServicesIcon, ServicesP} from './ServicesElements'

const Services = () => {
  return (
      <ServicesContainer id="services">
          <ServicesH1>Feedbacks de nossos clientes</ServicesH1>
          <ServicesWrapper>
              <ServicesCard>
                  {/*<ServicesIcon src={Icon1} />*/}
                  <ServicesH2>Pedro</ServicesH2>
                  <ServicesP>"Gostamos muito, estamos economizando mais, e ficamos contentes por estarmos contribuindo com o meio ambiente, utilizando fonte de energia limpa. Ficamos contentes também com a eficiência da empresa ABT Solar."</ServicesP>
              </ServicesCard>
              <ServicesCard>
                  {/*<ServicesIcon src={Icon2} />*/}
                  <ServicesH2>Paulo</ServicesH2>
                  <ServicesP>"A ABT superou minhas expectativas sendo eficiente no projeto e implantação do sistema. Além disso cuidou de toda parte burocrática até o funcionamento pleno do sistema."</ServicesP>
              </ServicesCard>
              <ServicesCard>
                  {/*<ServicesIcon src={Icon3} />*/}
                  <ServicesH2>Luiz Carlos</ServicesH2>
                  <ServicesP>"Gostaria de expressar meu contentamento sobre a qualidade dos produtos utilizados, instalação, monitoramento e acompanhamento do profissional e, evidentemente, redução dos custos na conta de energia."</ServicesP>
              </ServicesCard>
          </ServicesWrapper>
      </ServicesContainer>
  )
}

export default Services;