import React from 'react';
import {Container, Form, FormButton, FormContent, FormH1, FormInput, FormLabel, FormWrap, Icon, Text} from "./AdminElements";

const Admin = () => {
  return (
    <>
     <Container>
       <FormWrap>
         <Icon to="/">ABT Solar</Icon>
         <FormContent>
           <Form action="#">
             <FormH1>Preencha os dados necessários</FormH1>
             <FormLabel htmlFor='for'>Titulo do post</FormLabel>
             <FormInput type='text' required/>
             <FormLabel htmlFor='for'>Conteúdo  do post</FormLabel>
             <FormInput type='text' required/>
             <FormLabel htmlFor='for'>Imagem do post</FormLabel>
             <FormInput type='text' required/>
             <FormButton type='submit'>Postar</FormButton>
             <Text>Ao postar seus post será publicado na página</Text>
           </Form>
         </FormContent>
       </FormWrap>
     </Container>
    </>
  );
};

export default Admin;
