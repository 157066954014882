import styled from 'styled-components'
import { Link as LinkRouter } from 'react-router-dom'
import { Link as LinkScroll} from 'react-scroll'

export const Nav = styled.nav`
  //background: #fff;
  background: ${({scrollNav}) => (scrollNav ? '#fff' : 'transparent')};
  /* background: #FDC200; */
  height: 80px;
  margin-top: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  
  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1100px;
`
//
// export const NavLogo = styled(LinkRouter)`
//   color: #fff;
//   justify-self: flex-start;
//   cursor: pointer;
//   font-size: 1.5rem;
//   display: flex;
//   align-items: center;
//   margin-left: 24px;
//   font-weight: bold;
//   text-decoration: none;
// `

export const NavLogo = styled.img`
  color: #fc7404;
  justify-self: flex-start;
  cursor: pointer;
  display: flex;
  margin-bottom: auto;
  margin-top: auto;
  height: 70px;
  align-items: center;
  margin-left: 24px;
  font-weight: bold;
  text-decoration: none;
`

export const NavLogoCircle = styled.img`
  color: #fc7404;
  justify-self: flex-start;
  cursor: pointer;
  display: flex;
  margin-bottom: auto;
  margin-top: auto;
  height: 70px;
  align-items: center;
  margin-left: 24px;
  font-weight: bold;
  text-decoration: none;
  border: none;
  border-radius: 50%;
`

export const MobileIcon = styled.div`
  display: none;
  
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fc7404;
  }
`

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -22px;
  
  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const NavItem = styled.li`
  height: 80px;
`

export const NavLinks = styled(LinkScroll)`
  //color: #fff;
  //color: #1A1A1A;
  color: ${({scrollNav}) => (scrollNav ? '#1A1A1A' : '#fff')};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  
  &.active {
    //border-bottom: 3px solid #01bf71;
    border-bottom: 3px solid #fc7404;
  }
`

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  
  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const NavBtnLink = styled(LinkRouter)`
  border-radius: 50px;
  //background: #01bf71;
  /* background: #4053B2; */
  background: #93d796;
  white-space: nowrap;
  padding: 10px 22px;
  color: #010606;
  //color: #fff;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  
  &:hover {
    transition: all 0.2s ease-in-out;
      //background: #fff;
      background: #fc7404;
    color: #fff;
  }
`