import Img from '../../images/svg-1.svg'
import Img2 from '../../images/svg-2.svg'
import Img3 from '../../images/svg-3.svg'
import ImgHouse from '../../images/27.jpeg'
import ImgAbout from '../../images/12.jpeg'
import ImgMission from '../../images/mission.png'
import ImgVision from '../../images/17.jpeg'
import ImgValues from '../../images/5.jpeg'
import ImgBenefits from '../../images/28.jpeg'
import ImgHow from '../../images/how.png'

export const homeObjOne = {
  id: 'about',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Quem somos',
  headLine: 'Uma empresa especializada em sistemas de geração de energia solar fotovoltaica',
  description: 'Com profissionais com larga experiência em grandes corporações no setor de energia elétrica, o que traz um grande diferencial neste mercado, aliando a geração de energia fotovoltaica com as necessidades atuais e futuras de consumo de energia elétrica.',
  buttonLabel: 'Entre em contato',
  imgStart: true,
  // img: require('../../images/img-1.jpeg'),
  img: ImgAbout,
  alt: 'Quem somos',
  dark: true,
  primary: false,
  darkText: true,
  showButton: false,
  first: true
}

export const homeObjTwo = {
  id: 'mission',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Nossa missão',
  headLine: '',
  description: 'Nossa missão é oferecer soluções completas para todos os tipos de estabelecimentos como residências, comércios, indústrias e instalações rurais, com qualidade e suporte técnico diferenciado após a instalação. Também atuamos no suporte a projetos de energia fotovoltaica para novos empreendimentos, auxiliando os Engenheiros e Arquitetos na melhor alocação dos painéis solares, a fim de obter a melhor performance com o menor número de painéis solares.',
  buttonLabel: 'Entre em contato',
  imgStart: false,
  // img: require('../../images/img-1.jpeg'),
  img: ImgMission,
  alt: 'Nossa missão',
  dark: false,
  primary: false,
  darkText: true,
  showButton: false
}

export const homeObjThree = {
  id: 'vision',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Visão',
  headLine: 'A visão da ABT Solar',
  description: 'Ser reconhecida pela sua expertise e qualidade dos serviços prestados em sua região.',
  buttonLabel: 'Entre em contato',
  imgStart: true,
  // img: require('../../images/svg-3.svg'),
  img: ImgVision,
  alt: 'Visão',
  dark: false,
  primary: false,
  darkText: true,
  showButton: false
}

export const homeObjFour = {
  id: 'values',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Valores',
  headLine: 'O valores da empresa',
  description: 'Ética, profissionalismo, atendimento as normas e boas práticas de engenharia aplicadas aos projetos e instalações.',
  buttonLabel: 'Entre em contato',
  imgStart: false,
  // img: require('../../images/img-1.jpeg'),
  img: ImgValues,
  alt: 'Valores',
  dark: false,
  primary: false,
  darkText: true,
  showButton: false
}

export const homeObjFive = {
  id: 'whatIs',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'O que é a energia solar fotovoltaica?',
  headLine: '',
  description: 'A energia solar fotovoltaica é a energia obtida através da conversão da luz do sol em eletricidade, dessa forma, ela é considerada uma fonte de energia renovável, limpa e sustentável.\n' +
    'Essa tecnologia é totalmente aplicável nas residências, indústrias ou comércio, pois fornece energia elétrica compatível com a alimentação dos diversos equipamentos que encontramos nestes ambientes.',
  buttonLabel: 'Entre em contato',
  imgStart: false,
  img: ImgHouse,
  alt: 'O que é a energia solar fotovoltaica?',
  dark: false,
  primary: false,
  darkText: true,
  showButton: false
}

export const homeObjSix = {
  id: 'benefits',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Quais os benefícios da energia solar fotovoltaica?',
  headLine: '',
  description: 'Para o meio ambiente, não teremos escassez ou esgotamento da matéria prima porque se trata de uma energia limpa de fonte renovável.\n' +
    'Para o país, alguns benefícios da energia solar são: a redução no carregamento de redes, a minimização de perdas, a diversificação da matriz energética, entre outros.\n' +
    'Para os consumidores os benefícios de gerar sua própria energia se justificam pelo retorno do investimento que hoje está em torno de quatro e cinco anos e, além disso, de ficar imune aos aumentos consecutivos do preço da energia elétrica.',
  buttonLabel: 'Entre em contato',
  imgStart: false,
  // img: require('../../images/img-1.jpeg'),
  img: ImgBenefits,
  alt: 'Quais os benefícios da energia solar fotovoltaica?',
  dark: false,
  primary: false,
  darkText: true,
  showButton: false
}

export const homeObjSeven = {
  id: 'howWorks',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Como funciona a energia solar fotovoltaica?',
  headLine: '',
  description: 'Os painéis solares captam a luz do sol e geram a energia que é "transportada" até o inversor solar, responsável por converter a energia elétrica gerada para as características da nossa rede elétrica. A geração de energia ocorre por meio do efeito fotovoltaico.',
  buttonLabel: 'Entre em contato',
  imgStart: false,
  // img: require('../../images/img-1.jpeg'),
  img: ImgHow,
  alt: 'Valores',
  dark: false,
  primary: false,
  darkText: true,
  showButton: false
}