import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages";
import AdminPage from "./pages/admin";
import { Nav } from './components/Navbar/NavbarElements';

function App() {
  return (
    <Router>
      {/*<Nav />*/}
      <Routes>
        <Route path="/" element={<Home />} exact/>
        <Route path="/admin" element={<AdminPage />} exact/>
      </Routes>
    </Router>
  );
}

export default App;
