import styled from 'styled-components';

export const ServicesContainer = styled.div`
    height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #f9f9f9;

    @media screen and (max-width: 768px) {
        //height: 1100px;
        height: 1500px;
    }

    @media screen and (max-width: 480px) {
        //height: 1300px;
        height: 1500px;
    }
`

export const ServicesWrapper = styled.div`
    max-width: 1500px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 0 50px;

    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        padding: 0 0.5rem;
    }
`

export const ServicesCard = styled.div`
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    width: 420px;
    max-height: 800px;
    padding: 30px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }

  @media screen and (max-width: 768px) {
    width: 350px;
  }
`

export const FeedbacksImage = styled.img`
    height: 400px;
    width: 400px;
    margin-bottom: 10px;


  @media screen and (max-width: 1000px) {
    height: 200px;
    width: 200px;
  }

  @media screen and (max-width: 768px) {
    height: 300px;
    width: 300px;
  }
`

export const ServicesH1 = styled.h1`
    font-size: 2.5rem;
    color: #fc7404;
    margin-bottom: 64px;

    @media screen and (max-width: 480px) {
        font-size: 2rem;
      padding-left: 1rem;
    }
`

export const ServicesH2 = styled.h2`
    font-size: 1rem;
    margin-bottom: 10px;
`

export const ServicesP = styled.p`
    font-size: 1rem;
    text-align: center;
`