import styled from 'styled-components';

export const InfoContainer = styled.div`
  color: #fff;
  background: ${({lightBg}) => (lightBg ? '#f9f9f9' : '#010606')};
  
  @media screen and (max-width: 768px) {
    padding-top: 60px;
    padding-bottom: 250px;
    display: none;
  }
`

export const InfoWrapper = styled.div`
  z-index: 1;
  height: 860px;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 460px;
    margin-right: 0;
    margin-left: 0;
    padding: 0 2px;

    justify-content: start;
  }
`

export const InfoRow = styled.div`
  // display: grid;
  // grid-auto-columns: minmax(auto, 1fr);
  // align-items: center;
  // //grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};
  // grid-template-areas: 'col1 col2'; 
  display: flex;
  justify-content: start;
  width: 400px;
  //grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};
  grid-template-areas: 'col1 col2';
  
  @media screen and (max-width: 768px) {
    width: 100px;
  }
`

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  //grid-area: col1;
`

// export const Column2 = styled.div`
//   margin-bottom: 15px;
//   padding: 0 15px;
//   grid-area: col2;
// `

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  //padding-bottom: 60px;
`

export const TopLine = styled.p`
  //color: #01bf71;
  color: #fc7404;
  /* color: #FDC200; */
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({lightText}) => (lightText ? '#f7f8fa' : '#010606')};
  
  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({darkText}) => (darkText ? '#010606' : '#fff')};
`

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`

export const ImgWrap = styled.div`
  //max-width: 1055px;
  width: 1200px;
  height: 100%;
  padding-right: 16rem;

  @media screen and (max-width: 480px) {
    width: 300px;
    display: flex;
    flex-direction: row;
    margin-right: 200px;
  }
`

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`

export const PointsWrapper = styled.div`
  display: flex;
  width: 1000px;
  max-width: 1000px;
  gap: 1rem;

  @media screen and (max-width: 480px) {
    width: 100px;
    flex-direction: column;
  }

  @media screen and (max-width: 768px) {
    width: 100px;
    display: flex;
    flex-direction: column;
  }
`

export const PointsItem = styled.div`
  font-size: 18px;
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;

  @media screen and (max-width: 480px) {
    width: 350px;
    max-width: 350px;
    display: flex;
  }

  @media screen and (min-width: 768px) {
    width: 500px;
    max-width: 500px;
    display: flex;
  }
`

export const PointsText = styled.p`
  color: #000;
  font-size: 14px;
  text-align: left;
`

export const Circle = styled.div`
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fc7404;
  width: 90px;
  max-width: 90px;
  height: 30px;
  margin-right: 0.5rem;

  @media screen and (max-width: 480px) {
    width: 80px;
  }
`