import React from 'react';
import {Button} from '../ButtonElements';
import {
  Column2,
  ImgWrap,
  Column1,
  BtnWrap,
  Img,
  Heading,
  InfoRow,
  InfoContainer,
  InfoWrapper,
  TextWrapper,
  TopLine,
  Subtitle,
  Circle,
  PointsItem, PointsText, PointsWrapper
} from './InfoElements'

const InfoSection = ({lightBg, id, imgStart, topLine, lightText, headLine, darkText, description, img, alt, buttonLabel, primary, dark, dark2, showButton}) => {
  return (
    <>
     <InfoContainer lightBg={lightBg} id={id}>
       <InfoWrapper>
         <InfoRow imgStart={imgStart}>
           <Column1>
             <TextWrapper>
               <TopLine>{topLine}</TopLine>
               <Heading lightText={lightText}>{headLine}</Heading>
                {/*<Subtitle darkText={darkText}>{description}</Subtitle>*/}
               <PointsWrapper>
                 <PointsItem>
                   <Circle>
                     1
                   </Circle>
                   <PointsText>As Placas Fotovoltaicas são responsáveis pela transformação da radiação solar em eletricidade.</PointsText>
                 </PointsItem>
                 <PointsItem>
                   <Circle>
                     2
                   </Circle>
                   <PointsText>
                     Essa energia gerada pela radiação é convertida em corrente contínua e possui variações de acordo com a potência e tipo de cada placa
                   </PointsText>
                 </PointsItem>
                 <PointsItem>
                   <Circle>
                     3
                   </Circle>
                   <PointsText>
                     O inversor ou microinversor converte a corrente contínua gerada pelas placas fotovoltaicas em corrente alternada.
                   </PointsText>
                 </PointsItem>
               </PointsWrapper>
             </TextWrapper>
             <ImgWrap>
               <Img src={img} alt={alt}/>
             </ImgWrap>
           </Column1>

           {/*<Column2>*/}
           {/*  <ImgWrap>*/}
           {/*    <Img src={img} alt={alt}/>*/}
           {/*  </ImgWrap>*/}
           {/*</Column2>*/}
         </InfoRow>

       </InfoWrapper>
     </InfoContainer>
    </>
  );
};

export default InfoSection;
