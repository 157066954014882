import React from 'react'
import Image1 from '../../images/1.png'
import Image2 from '../../images/2.png'
import Image3 from '../../images/3.png'
import {ServicesContainer, ServicesWrapper, ServicesH1, ServicesH2, ServicesCard, FeedbacksImage, ServicesP} from './FeedbacksElements'

const Services = () => {
  return (
      <ServicesContainer id="services">
          <ServicesH1>Nossas últimas instalações</ServicesH1>
          <ServicesWrapper>
              <ServicesCard>
                  <FeedbacksImage src={Image1} />
              </ServicesCard>
              <ServicesCard>
                <FeedbacksImage src={Image2} />
              </ServicesCard>
              <ServicesCard>
                <FeedbacksImage src={Image3} />
              </ServicesCard>
          </ServicesWrapper>
      </ServicesContainer>
  )
}

export default Services;