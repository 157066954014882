import React, {useCallback, useState} from 'react';
import {SidebarContainer, Icon, CloseIcon, SidebarMenu, SidebarWrapper, SideBtnWrap, SidebarRoute, SidebarLink} from './SidebarElements'
import {WhatsAppIcon} from "../HeroSection/HeroElements";
import {Button} from "../ButtonElements";

const Sidebar = ({isOpen, toggle}) => {
  const [hover, setHover] = useState(false)

  const onHover = useCallback(() => {
    setHover(!hover)
  }, [hover])

  const onSubmit = useCallback(
    () => {
      const message = 'Olá, estou entrando em contato pois gostaria de realizar um orçamento de instalação do sistema de placas fotovoltaicas'
      const number = '5519981699615'
      let url = `https://api.whatsapp.com/send?phone=${number}`
      url += `&text=${encodeURI(message)}&app_absent=0`

      window.open(url);
    },
    [],
  );


  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to="about" onClick={toggle} smooth={true} duration={500} spy={true} exact='true' offset={-80}>
            Quem somos
          </SidebarLink>
          <SidebarLink to="mission" onClick={toggle} smooth={true} duration={500} spy={true} exact='true' offset={-80}>
            Nossa missão
          </SidebarLink>
          <SidebarLink to="vision" onClick={toggle} smooth={true} duration={500} spy={true} exact='true' offset={-80}>
              Visão
          </SidebarLink>
          <SidebarLink to="values" onClick={toggle} smooth={true} duration={500} spy={true} exact='true' offset={-80}>
              Valores
          </SidebarLink>
        </SidebarMenu>
        <SideBtnWrap>
          {/*<SidebarRoute to="/contact">Entre em contato</SidebarRoute>*/}
          <Button to="contact" onMouseEnter={onHover} onMouseLeave={onHover} primary dark onClick={onSubmit}>
            Peça um orçamento via WhatsApp {hover ? <WhatsAppIcon /> : ''}
          </Button>
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  );
}

export default Sidebar;
